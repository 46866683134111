import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";

import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ImageHero from "../components/ImageHero";
import { HTMLContent } from "../components/Content";
import ContactHero from "../components/ContactHero";
import { designs } from "../constants/design-services";

export const DesignServicePageTemplate = ({
  childImageSharp,
  title,
  heading,
  description,
}) => {
  return (
    <>
      <ImageHero heading={title} childImageSharp={childImageSharp} />
      <div className="content">
        <section className="section section--gradient">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column is-7 is-offset-1">
                  <Fade>
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </Fade>
                </div>
              </div>
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="content">
                    <Fade>
                      <p>
                        WIMMIC is focused on the design of standard or custom
                        MMICs for any required application in different
                        technologies, such as GaN and GaAs. The expertise of our
                        design team is also made accessible to our customers
                        through our consulting services.
                      </p>
                    </Fade>
                    <Fade>
                      {designs.map(({ title, description }, i) => (
                        <div
                          key={i}
                          className="box"
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="number-left is-hidden-mobile"
                            style={{
                              color: "rgba(0, 0, 0, 0.5)",
                              fontSize: 38,
                              padding: 40,
                            }}
                          >
                            {i + 1}
                          </div>
                          <div>
                            <b>{title}</b>
                            <p>{description}</p>
                          </div>
                        </div>
                      ))}
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ContactHero />
    </>
  );
};

DesignServicePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const DesignServicePage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout>
      <DesignServicePageTemplate
        childImageSharp={frontmatter.image.childImageSharp}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        contentComponent={HTMLContent}
        content={html}
      />
    </Layout>
  );
};

DesignServicePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default DesignServicePage;

export const designServicePageQuery = graphql`
  query DesignServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        full_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
