export const designs = [
  {
    title: "Defining the specification requirements",
    description: `Our designers will work with you to identify the
      best solutions based on your technical needs. Our
      knowledge will help you get the best performance
      from our MMICs. Thanks to our experience with different
      technologies and foundries, we are able to define
      the best specifications and processes to work with.`
  },
  {
    title: "Layout and simulation",
    description: `WIMMIC believes that the layout of the design and
      the design simulation are the most critical parts of
      the design process. This is why our designers pay
      special attention to every detail to ensure the
      product’s reliability and performance.`
  },
  {
    title: "Electromagnetic Analysis",
    description: `The interactions between the different parts of a
      circuit are vital for its reliability and
      efficiency. We measure them by using the most
      advanced electromagnetic simulations.`
  },
  {
    title: "Measuring our designs",
    description: `WIMMIC strongly considers that measuring and testing
      our designs is as important as the design itself.
      This is why all our circuits are tested in our
      facilities to measure all types of performance
      characteristics.`
  },
  {
    title: "Packaging",
    description: `At WIMMIC, packaging is considered an essential part
      of our design process.`
  },
  {
    title: "Evaluation Boards",
    description: `Providing the customer with a test device for the
      MMIC is an important part of our design service. Our
      evaluation boards are unique demonstrations and
      development platforms for our clients.`
  },
  {
    title: "Technical Support",
    description: `We provide the client with the necessary technical
      support so that our MMICs meet their objective. In
      addition, we provide application notes to facilitate
      the assembly of our products.`
  }
];
